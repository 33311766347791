.portal-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    // height: 100%;

    .portal-page-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 52px;
    }

    .portal-page-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        margin: -7.5px;

        .portal-page-row {
            display: flex;
            margin: 7.5px 0;

            .portal-page-column {
                box-sizing: border-box;

                margin: 0 7.5px;
                display: flex;
            }

            &:last-of-type {
                margin-bottom: 22.5px;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .portal-page-header {
        // flex-direction: column;
        min-height: unset;

        > div {
            flex-direction: row;
            width: 100%;
            button {
                margin: 0;
            }
        }
        button {
            // width: 100%;
            margin-top: 5px;
            margin-bottom: 15px;
        }

        .portal-page-header-actions {
            button {
                width: 100%;
                margin-top: 5px;
                margin-bottom: 5px;

                &:last-of-type {
                    margin-bottom: 40px;
                }
            }
        }
    }
}
