.portal-page-column-powerbi {
    width: 100%;
    height: 100%;
    padding: 0;
}

.portal-page-column-powerbi-actions {
    display: flex;
    align-self: end;
    margin-bottom: 8px;
}
